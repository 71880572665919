<script setup lang="ts">
defineProps({
  pageData: {
    type: Object,
    default: () => {}
  }
})

const homepageSearch = {
  searchSettings: {
    name: "search",
    label: "Search label",
    placeholder: "Cerca per prodotto, marca o sintomo",
    isPlaceholderAnimated: false,
    modelValue: "",
    disabled: false,
    showTextIcon: true
  },
  bgImageLarge: getImageData({
    url: "/i5oh5hzamkwo/24jIqN36sXU0vl1vPwWEZa/7cfa9a3197776c9e0d8d09ddb8e7d0a9/banner-menu.svg",
    width: 1800,
    height: 200
  })
}
</script>

<template>
  <div class="generic">
    <OrganismsSuggestedCategories class="md:hidden" />
    <!-- TODO: Delete component if not nedeed anymore -->
    <!-- <SearchSearchbarHero v-bind="homepageSearch" /> -->
    <DynamicContent
      class="cms-content grid-container"
      :components="pageData.content"
    />
  </div>
</template>

<style lang="scss" scoped>

.cms-content {
  :deep(.cms-component-conditional-logic) {
    @apply col-span-full;
  }

  :deep(.cms-component-popular-brands) {
    @apply col-span-full my-0;
  }

  :deep(.cms-component-algolia-tabs-carousel) {
    @apply col-span-full;
  }

  :deep(.cms-component-banner) {
    @apply col-span-full;
  }

  :deep(.cms-component-banner-categories-of-the-moment) {
    @apply col-span-full;
  }

  :deep(.cms-component-banner-our-brands) {
    @apply col-span-full;
  }

  :deep(.cms-component-editorial-banner) {
    @apply col-span-full;
  }

  :deep(.cms-component-super-discount-products) {
    @apply col-span-full;
  }

  :deep(.cms-order-or-cart-recap) {
    @apply col-span-full;
  }

  :deep(.cms-component-reviews) {
    @apply col-span-full;
  }

  :deep(.cms-component-promotions) {
    @apply col-span-full;
  }

  :deep(.cms-component-articles) {
    @apply col-span-full;
  }

  :deep(.cms-seo-description) {
    @apply padded col-span-full;
  }

  :deep(.cms-brand-banner) {
    @apply col-span-full;
  }
}

</style>
