<script setup lang="ts">
const { topHeader } = await useHeaderConnect()
</script>

<template>
  <nav
    class="
      flex-no-wrap flex
      max-h-full
      items-center
      gap-x-5 gap-y-1
      overflow-x-auto
      p-5
      scrollbar-hide
    "
  >
    <UtilsButton
      v-for="(item, index) in topHeader"
      :key="index"
      :text="item.label"
      :path="`/c-${item.path}`"
      class="
        custom-btn-beaver-bg-transparent
        header-btn
        flex
        h-full
        w-full
        min-w-fit
        items-center
      "
    />
  </nav>
</template>
